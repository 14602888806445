const EMBED_SIZE = 'embed-size';
const AMP_SENTINEL = 'amp';
const FRAME_PREFIX = 'data-frame-';
const FRAME_AUTO_INITIALIZED_ATTRIBUTE = `${FRAME_PREFIX}auto-initialized`;
const FRAME_SRC_ATTRIBUTE = `${FRAME_PREFIX}src`;

export {
  AMP_SENTINEL,
  EMBED_SIZE,
  FRAME_PREFIX,
  FRAME_AUTO_INITIALIZED_ATTRIBUTE,
  FRAME_SRC_ATTRIBUTE,
};
